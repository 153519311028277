// stores/localeStore.js
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const defaultLocale = { label: 'EN', value: 'en', icon: 'i-flag-us-4x3' }
const savedLocale = process.client ? (JSON.parse(localStorage.getItem('locale')) || defaultLocale) : defaultLocale;

export const useLocaleStore = defineStore('locale', () => {
  const { locale: i18nLocale, setLocale: setLocaleI18N } = useI18n({ useScope: 'global' });
  const locale = ref(savedLocale);
  setLocaleI18N(savedLocale.value);

  function setLocale(newLocale) {
    locale.value = newLocale
    if (process.client) {
      localStorage.setItem('locale', JSON.stringify(newLocale))
    }
  }

  watch(locale, (newLocale) => {
    setLocaleI18N(newLocale.value) // sau orice altă mapare folosești pentru localele din vue-i18n
  })

  return { locale, setLocale }
})
